import { AspectRatio, Box, type BoxProps, Flex, Image } from "@chakra-ui/react";
import token from "@sheinc/design-tokens";
import { Typography } from "@wpf-ui/components/essentials/typography";
import Link from "next/link";
import { type FC } from "react";
import { sendGTMEvent } from "@next/third-parties/google";

interface ContentCardProps extends BoxProps {
  imageSrc: string;
  title: string;
  withPadding?: boolean;
  linkUrl?: string;
  targetBlank?: boolean;
}

export const ContentCard: FC<ContentCardProps> = ({ imageSrc, withPadding, title, linkUrl, targetBlank, ...rest }) => {
  return (
    <Link
      href={linkUrl ?? "#"}
      onClick={(e) => {
        e.preventDefault();
        sendGTMEvent({ event: "contentCardClicked", value: { url: linkUrl, title } });
        const target = targetBlank ? "_blank" : "_self";
        window.open(linkUrl, target);
      }}
    >
      <Box
        boxShadow="0px 4px 12px rgba(0, 0, 0, 0.08)"
        cursor="pointer"
        bg={token.color.bg.white.value}
        borderRadius="0.5rem"
        p={withPadding ? "0.75rem" : "0"}
        {...rest}
      >
        <AspectRatio ratio={withPadding ? 2 / 1 : 16 / 9}>
          <Image
            src={imageSrc}
            alt="image"
            cursor="pointer"
            objectFit="cover"
            fallbackSrc="/images/default-card-thumbnail.png"
            borderTopRadius={withPadding ? "0" : "0.5rem"}
          />
        </AspectRatio>

        <Box pt="0.75rem" pb={withPadding ? "0" : "0.75rem"}>
          <Flex justifyContent="center" alignItems="center">
            <Typography variant="body2b">{title}</Typography>
          </Flex>
        </Box>
      </Box>
    </Link>
  );
};
