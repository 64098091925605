import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { SEARCH_PROJECTS_KEY } from "@wpf-ui/constants/queryKeys";
import {
  type SearchProjectsInput,
  SearchPublicProjectsDocument,
  type SearchPublicProjectsQuery,
} from "@wpf-ui/gql/generated-gateway/graphql";
import { gql, useClient } from "urql";

gql`
  query SearchPublicProjects($page: Int, $itemsPerPage: Int!, $input: SearchProjectsInput!) {
    searchProjects(page: $page, itemsPerPage: $itemsPerPage, input: $input) {
      __typename
      ... on PublicProjectConnection {
        nodes {
          ...ProjectCardFields
        }
        pageInfo {
          page
          hasNextPage
        }
        totalCount
      }
    }
  }
`;

export const useSearchPublicProjectsQuery = (
  currentSearchTerms: SearchProjectsInput,
  options?: { itemsPerPage?: number },
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- infer
) => {
  const queryClient = useQueryClient();
  const client = useClient();
  const result = useInfiniteQuery({
    queryKey: [SEARCH_PROJECTS_KEY.search(currentSearchTerms)],
    queryFn: async ({
      pageParam,
    }): Promise<Extract<SearchPublicProjectsQuery["searchProjects"], { __typename: "PublicProjectConnection" }>> => {
      const result = await client
        .query(
          SearchPublicProjectsDocument,
          { input: currentSearchTerms, itemsPerPage: options?.itemsPerPage ?? 30, page: pageParam },
          { requestPolicy: "network-only" },
        )
        .toPromise();
      if (result.data?.searchProjects.__typename !== "PublicProjectConnection") {
        return {
          __typename: "PublicProjectConnection",
          nodes: [],
          pageInfo: { hasNextPage: false, page: 1 },
          totalCount: 0,
        };
      }
      return result.data.searchProjects;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage.pageInfo.hasNextPage && lastPage.pageInfo.page ? lastPage.pageInfo.page + 1 : undefined;
    },
    staleTime: 1000 * 60 * 5,
  });

  return {
    ...result,
    resetCache: () => {
      queryClient.removeQueries({ queryKey: [SEARCH_PROJECTS_KEY.search(currentSearchTerms)] });
    },
  };
};
