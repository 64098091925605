import { Flex, Grid } from "@chakra-ui/react";
import tokens from "@sheinc/design-tokens";
import { ContentCard } from "@wpf-ui/components/essentials/card/ContentCard";
import { Typography } from "@wpf-ui/components/essentials/typography";
import { type ReactNode, type FC } from "react";

export const RecommendedPlusLessonsByInterest: FC = () => {
  return (
    <RecommendedPlusLessons heading="おすすめプラスレッスン【やりたいこと別】">
      <ContentCard
        imageSrc="/images/top-page/work_communication_skill.png"
        title="初めてのお仕事に挑戦したい！"
        linkUrl="https://shelikes.jp/plus_lessons/7"
        withPadding
        targetBlank
      />
      <ContentCard
        imageSrc="/images/top-page/self_coaching.png"
        title="自分の強みを見つけたい！"
        linkUrl="https://shelikes.jp/plus_lessons/55"
        withPadding
        targetBlank
      />
      <ContentCard
        imageSrc="/images/top-page/side_job_talent.png"
        title="複業(副業)がしたい！"
        linkUrl="https://shelikes.jp/plus_lessons/39"
        withPadding
        targetBlank
      />
      <ContentCard
        imageSrc="/images/top-page/job_hunting.png"
        title="転職活動のポイントを知りたい！"
        linkUrl="https://shelikes.jp/plus_lessons/1"
        withPadding
        targetBlank
      />
    </RecommendedPlusLessons>
  );
};

export const RecommendedPlusLessonsByConcern: FC = () => {
  return (
    <RecommendedPlusLessons heading="おすすめプラスレッスン【お悩み別】">
      <ContentCard
        imageSrc="/images/top-page/career_break.png"
        title="キャリアについて迷いがある"
        linkUrl="https://shelikes.jp/plus_lessons/47"
        withPadding
        targetBlank
      />
      <ContentCard
        imageSrc="/images/top-page/career_counseling.png"
        title="キャリアとライフの両立に悩んでいる"
        linkUrl="https://shelikes.jp/plus_lessons/52"
        withPadding
        targetBlank
      />
      <ContentCard
        imageSrc="/images/top-page/how_to_achieve_your_dream_career.png"
        title="目標はあるが叶え方が分からない"
        linkUrl="https://shelikes.jp/plus_lessons/60"
        withPadding
        targetBlank
      />
      <ContentCard
        imageSrc="/images/top-page/interview_strategies.png"
        title="面接に苦手意識がある"
        linkUrl="https://shelikes.jp/plus_lessons/83"
        withPadding
        targetBlank
      />
    </RecommendedPlusLessons>
  );
};

interface RecommendedPlusLessonsProps {
  heading: string;
  children: ReactNode;
}

const RecommendedPlusLessons: FC<RecommendedPlusLessonsProps> = ({ heading, children }) => {
  return (
    <Flex
      bg={tokens.color.bg.sheblueLight.value}
      padding={{
        sp: `${tokens.space[600].value} ${tokens.space[400].value} ${tokens.space[800].value} ${tokens.space[400].value}`,
        pc: `${tokens.space[600].value} 0 ${tokens.space[800].value} 0`,
      }}
      justifyContent="center"
    >
      <Flex gap="1rem" flexDirection="column" w="100%" maxW={{ sp: "100%", pc: "1024px" }} alignItems="center">
        <Typography
          variant={{ sp: "heading5b", pc: "heading4b" }}
          color={tokens.color.text.sheblueDarken.value}
          mb="1rem"
          alignSelf="flex-start"
        >
          {heading}
        </Typography>

        <Grid
          templateColumns={{ sp: "repeat(2, 1fr)", pc: "repeat(4, 1fr)" }}
          gap={{ sp: "0.5rem", pc: "1rem" }}
          width="100%"
          justifyContent="center"
        >
          {children}
        </Grid>
      </Flex>
    </Flex>
  );
};
