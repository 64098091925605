// https://zenn.dev/taisei_13046/books/133e9995b6aadf/viewer/c6c0e7

import { type SearchProjectsInput } from "@wpf-ui/gql/generated-gateway/graphql";

/**
 * react-queryのキーを一元管理
 * 使うときは、キーを直接指定せずに、このファイルからインポートして使う
 * 例: queryClient.invalidateQueries({ queryKey: GET_PROJECT_KEY.detail(projectId) });
 *
 * キー名には原則としてGraphQLのクエリ名を使う
 * 例: query GetCompetitionEntries($projectId: ID!)
 *     →all: ["GetCompetitionEntries"] as const,
 *
 * これをやっておかないと、クエリ名がかぶったりしたときに意図しないキャッシュが使われる可能性があるため。
 */

export const GET_RESUBMITTED_ENTRY_WORK_KEY = {
  all: ["GetResubmittedEntryWork"] as const,
  detail: (entryWorkId: string) => [...GET_RESUBMITTED_ENTRY_WORK_KEY.all, entryWorkId] as const,
};

export const GET_RESUBMIT_PROJECT_KEY = {
  all: ["GetResubmitProject"] as const,
  detail: (projectId: string) => [...GET_RESUBMIT_PROJECT_KEY.all, projectId] as const,
};

export const RESUBMIT_CONTAINER_GET_PASTURE_KEY = {
  all: ["ResubmitContainerGetPasture"] as const,
};

export const GET_ENTRY_WORKS_FOR_SHE_COMPETITION_ENTRIES_PROJECT_KEY = {
  all: ["GetEntryWorksForSheCompetitionEntries"] as const,
  detail: (projectId: string) => [...GET_ENTRY_WORKS_FOR_SHE_COMPETITION_ENTRIES_PROJECT_KEY.all, projectId] as const,
};

export const SEARCH_PROJECTS_KEY = {
  all: ["SearchProjects"] as const,
  search: (searchTerms: SearchProjectsInput) => [...SEARCH_PROJECTS_KEY.all, searchTerms] as const,
};

export const GET_ALL_SHE_COMPETITION_ADOPTED_ENTRY_WORKS_KEY = {
  all: ["GetAllSheCompetitionAdoptedEntryWorks"] as const,
};
