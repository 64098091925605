import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/ja";
import utc from "dayjs/plugin/utc";
dayjs.extend(relativeTime, {
  rounding: Math.floor,
  thresholds: [
    { l: "s", r: 1 },
    { l: "m", r: 1 },
    { l: "mm", r: 59, d: "minute" },
    { l: "h", r: 1 },
    { l: "hh", r: 23, d: "hour" },
    { l: "d", r: 1 },
    { l: "dd", d: "day" },
    // 月以上の単位は使わず、「日」までしか扱わない
    // 仕様変更が必要な場合はこの設定を変更する必要があります
    { l: "M" },
    { l: "MM" },
    { l: "y" },
    { l: "yy" },
  ],
});
dayjs.extend(updateLocale);
dayjs.extend(utc);

dayjs.updateLocale("ja", {
  relativeTime: {
    future: "あと%s",
    past: "%s前",
    s: "数秒",
    m: "1分",
    mm: "%d分",
    h: "1時間",
    hh: "%d時間",
    d: "1日",
    dd: "%d日",
    M: "1ヶ月",
    MM: "%dヶ月",
    y: "1年",
    yy: "%d年",
  },
});

export function formatJa(d: dayjs.Dayjs, template: string): string {
  return d.locale("ja").format(template);
}

export function formatRelativeTime(startDateStr: string, endDateStr?: string): string {
  // Use the current date if the second date is not provided
  const endDate = endDateStr ? dayjs.utc(endDateStr) : dayjs.utc();

  // Calculate the difference in days
  const start = dayjs.utc(startDateStr);
  return endDate.locale("ja").from(start);
}
