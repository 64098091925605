import { Box, Flex, Grid } from "@chakra-ui/react";
import tokens from "@sheinc/design-tokens";
import { ContentCard } from "@wpf-ui/components/essentials/card/ContentCard";
import { LineHeading } from "@wpf-ui/components/essentials/heading/LineHeading";
import { Typography } from "@wpf-ui/components/essentials/typography";
import { type FC } from "react";

export const OccupationGuideSection: FC = () => {
  return (
    <Flex
      gap="1.5rem"
      flexDirection="column"
      padding={{
        sp: `0 ${tokens.space[400].value} 0 ${tokens.space[400].value}`,
        pc: "0 0",
      }}
    >
      <LineHeading>
        <Typography variant="heading4b" color={tokens.color.text.default.value}>
          お役立ちコンテンツ
        </Typography>
      </LineHeading>
      <Box>
        <Typography variant="heading4b" color={tokens.color.text.sheblueDarken.value} mb="1rem">
          職種ガイド
        </Typography>
        <Grid templateColumns={{ sp: "repeat(2, 1fr)", pc: "repeat(4, 1fr)" }} gap={{ sp: "0.5rem", pc: "1rem" }}>
          <ContentCard
            imageSrc="/images/top-page/sales_supporter.png"
            title="営業事務・営業アシスタント"
            linkUrl="https://sheinc.notion.site/6ad10de108fb431cb9566884a59e23b9"
            targetBlank
          />
          <ContentCard
            imageSrc="/images/top-page/general_office_work.png"
            title="一般事務"
            linkUrl="https://sheinc.notion.site/77594e00a97e49a29292a70c53918b9e"
            targetBlank
          />
          <ContentCard
            imageSrc="/images/top-page/inside_sales.png"
            title="インサイドセールス"
            linkUrl="https://sheinc.notion.site/4b15ee0a475044598eba16f9c043725c"
            targetBlank
          />
          <ContentCard
            imageSrc="/images/top-page/customer_support.png"
            title="カスタマーサポート"
            linkUrl="https://sheinc.notion.site/a77cdc7cc3814b888426e72c65744901"
            targetBlank
          />
          <ContentCard
            imageSrc="/images/top-page/system_engineer.png"
            title="システムエンジニア"
            linkUrl="https://sheinc.notion.site/7e8b19253c924c12b93164abefac3a75"
            targetBlank
          />
          <ContentCard
            imageSrc="/images/top-page/web_director.png"
            title="Webディレクター"
            linkUrl="https://sheinc.notion.site/Web-d51980b1b44d4155b37ab382f5c4601f"
            targetBlank
          />
          <ContentCard
            imageSrc="/images/top-page/web_designer.png"
            title="Webデザイナー"
            linkUrl="https://sheinc.notion.site/Web-176f4ea360ed80c9ba35c5f2360a9350"
            targetBlank
          />
          <ContentCard
            imageSrc="/images/top-page/web_marketer.png"
            title="Webマーケター"
            linkUrl="https://sheinc.notion.site/Web-176f4ea360ed8079ad35e2916b61de9c"
            targetBlank
          />
          <ContentCard
            imageSrc="/images/top-page/web_writer.png"
            title="Webライター"
            linkUrl="https://sheinc.notion.site/Web-176f4ea360ed80789719fadab9849ca8"
            targetBlank
          />
        </Grid>
      </Box>
    </Flex>
  );
};
