import { Box, Flex, Grid, Link } from "@chakra-ui/react";
import tokens from "@sheinc/design-tokens";
import { LineHeading } from "@wpf-ui/components/essentials/heading/LineHeading";
import { Typography } from "@wpf-ui/components/essentials/typography";
import { useEffect, type FC } from "react";

export const FooterSection: FC = () => {
  // useEffectでscriptを挿入する理由:
  // FacebookやTwitterのスクリプトはクライアント環境でのみ動作し、サーバーサイドでは不要。
  // サーバーサイドレンダリング時にこれらのスクリプトを含めると、Hydrationエラーが発生する可能性がある。
  useEffect(() => {
    const scriptContainer = document.getElementById("custom-script-container");
    if (scriptContainer) {
      const fbScript = document.createElement("script");
      fbScript.async = true;
      fbScript.defer = true;
      fbScript.crossOrigin = "anonymous";
      fbScript.src = "https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v21.0&appId=752797118825781";
      scriptContainer.appendChild(fbScript);

      const twitterScript = document.createElement("script");
      twitterScript.async = true;
      twitterScript.src = "https://platform.twitter.com/widgets.js";
      scriptContainer.appendChild(twitterScript);
    }
  }, []);

  return (
    <Box bg="#2E3B46" p="2rem" color={tokens.color.text.white.value}>
      {/* スクリプトを挿入するためのコンテナ */}
      <div id="custom-script-container" style={{ display: "none" }} data-chromatic="ignore"></div>
      <Box maxW="1024px" mx="auto">
        <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap="2rem" mb="2rem">
          <Box>
            <Box mb="0.5rem">
              <LineHeading bgColor="none" lineColor={tokens.color.object.white.value} padding="0 0.5rem">
                <Typography variant="body2b">SHE | ミレニアルライフコーチングカンパニー</Typography>
              </LineHeading>
            </Box>
            <Typography variant="body2">
              <Link href="https://shelikes.jp" target="_blank">
                SHElikes
              </Link>{" "}
              |{" "}
              <Link href="https://shares.shelikes.jp/" target="_blank">
                SHEshares
              </Link>{" "}
              | <Link href="https://money.shelikes.jp/">SHEmoney</Link> | SHE WORKS |{" "}
              <Link
                href="https://shelikes.jp/multicreator/?utm_source=shesta&utm_medium=works&utm_campaign=mcc_worksfooter"
                target="_blank"
              >
                SHElikes MULTI CREATOR COURSE Designer
              </Link>
            </Typography>
          </Box>
          <Box>
            <Box mb="0.5rem">
              <LineHeading bgColor="none" lineColor={tokens.color.object.white.value} padding="0 0.5rem">
                <Typography variant="body2b">SHEに関する情報</Typography>
              </LineHeading>
            </Box>
            <Typography variant="body2">
              <Link href="https://she-inc.co.jp/about" target="_blank">
                運営会社
              </Link>{" "}
              |{" "}
              <Link href="https://she-inc.co.jp/common/agreement.html" target="_blank">
                利用規約集
              </Link>{" "}
              |{" "}
              <Link href="https://shelikes.jp/pricing" target="_blank">
                料金プラン
              </Link>{" "}
              |{" "}
              <Link href="https://she-inc.co.jp/common/policy.html" target="_blank">
                プライバシーポリシー
              </Link>{" "}
              |{" "}
              <Link
                href="https://she-files-public.s3-ap-northeast-1.amazonaws.com/%E7%89%B9%E5%AE%9A%E5%95%86%E5%8F%96%E5%BC%95%E6%B3%95%E3%81%AB%E5%9F%BA%E3%81%A4%E3%82%99%E3%81%8F%E8%A1%A8%E7%A4%BA.pdf"
                target="_blank"
              >
                特定商取引法に基づく表示
              </Link>{" "}
              |{" "}
              <Link href="https://shelikes.jp/faq" target="_blank">
                お問い合わせ
              </Link>{" "}
              |{" "}
              <Link href="https://sheinc.notion.site/SHE-d7b37bfd7fb64b4cbba5c0391b5e4041" target="_blank">
                コミュニケーションポリシー
              </Link>
            </Typography>
          </Box>
        </Grid>

        <Box textAlign="center" mb="2rem">
          <Typography variant="body3">© SHE inc. All Rights Reserved.</Typography>
        </Box>

        <Flex
          justify="center"
          alignItems={"center"}
          gap="2rem"
          flexDirection={{ sp: "column", pc: "row" }}
          w="100%"
          data-chromatic="ignore"
          minHeight={{ sp: "calc(600px + 600px + 2rem)", pc: "600px" }}
        >
          <Flex w={{ sp: "100%", pc: "400px" }} justifyContent={"center"}>
            <a
              className="twitter-timeline"
              data-lang="ja"
              data-width="400"
              data-height="600"
              href="https://twitter.com/she_officials?ref_src=twsrc%5Etfw"
            >
              Tweets by she_officials
            </a>{" "}
          </Flex>

          <Box
            className="fb-page"
            data-href="https://web.facebook.com/shelikes.jp"
            data-tabs="timeline"
            data-width="400"
            data-height="600"
            data-small-header="false"
            data-adapt-container-width="true"
            data-hide-cover="false"
            data-show-facepile="true"
            w={{ sp: "100%", pc: "400px" }}
          >
            <blockquote cite="https://web.facebook.com/shelikes.jp" className="fb-xfbml-parse-ignore">
              <a href="https://web.facebook.com/shelikes.jp">SHE株式会社（SHElikes / SHEmoney）</a>
            </blockquote>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
