import { useSearchPublicProjectsQuery } from "@wpf-ui/hooks/useSearchPublicProjectsQuery";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- infer return type for hooks
export function useTopPageContainer() {
  const spotBusinessData = useSearchPublicProjectsQuery(
    {
      jobGenre: "SPOT",
      onlyShowOpen: true,
      workCaseType: ["BUSINESS"],
      sort: [{ field: "ENTRY_CLOSES_AT", order: "ASC" }],
    },
    { itemsPerPage: 4 },
  );
  const spotSheData = useSearchPublicProjectsQuery(
    {
      jobGenre: "SPOT",
      onlyShowOpen: true,
      workCaseType: ["SHE"],
      sort: [{ field: "ENTRY_CLOSES_AT", order: "ASC" }],
    },
    { itemsPerPage: 4 },
  );
  const spotBusinessProjects = spotBusinessData.data?.pages.flatMap((v) => v.nodes) ?? [];
  const spotSheProjects = spotSheData.data?.pages.flatMap((v) => v.nodes) ?? [];
  const spotProjects =
    spotSheProjects.length === 0
      ? spotBusinessProjects.slice(0, 4) // SHE案件が0件の場合は法人案件4件
      : [...spotBusinessProjects.slice(0, 3), ...spotSheProjects].slice(0, 4); // 法人案件優先でMax3件で、残りはSHE案件でMax4件
  const outsourceData = useSearchPublicProjectsQuery(
    { jobGenre: "OUTSOURCING", onlyShowOpen: true, sort: [{ field: "ENTRY_CLOSES_AT", order: "ASC" }] },
    { itemsPerPage: 1 },
  );
  const outsourceProjects = outsourceData.data?.pages.flatMap((v) => v.nodes) ?? [];
  const employmentData = useSearchPublicProjectsQuery(
    { jobGenre: "EMPLOYMENT", onlyShowOpen: true, sort: [{ field: "ENTRY_CLOSES_AT", order: "ASC" }] },
    { itemsPerPage: 3 },
  );
  const employmentProjects = employmentData.data?.pages.flatMap((v) => v.nodes) ?? [];
  return {
    isProjectLoading: spotSheData.isLoading || outsourceData.isLoading || employmentData.isLoading,
    spotProjects,
    outsourceProjects,
    employmentProjects,
  };
}
