import { useCurrentUser } from "@wpf-ui/contexts";

interface Features {
  jobListing: boolean;
  writerCompetition: boolean;
  resultAnnouncement: boolean;
  listingFront: boolean;
  sheCompetitionAdoptedEntryWorks: boolean;
  topPage: boolean;
}

const defaultFeatures = {
  enabledFeatures: new Set<string>(),
};

export function useFeatures(): Features {
  const { enabledFeatures } = useCurrentUser() ?? defaultFeatures;
  return {
    jobListing: enabledFeatures.has("job_listing"),
    writerCompetition: enabledFeatures.has("writer_competition"),
    resultAnnouncement: enabledFeatures.has("result_announcement"),
    listingFront: enabledFeatures.has("listing_front"),
    sheCompetitionAdoptedEntryWorks: enabledFeatures.has("she_competition_adopted_entry_works"),
    topPage: enabledFeatures.has("top_page"),
  };
}
