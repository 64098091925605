import { Box, Flex, Image } from "@chakra-ui/react";
import tokens from "@sheinc/design-tokens";
import { IconImage } from "@wpf-ui/components/essentials/icon";
import { Typography } from "@wpf-ui/components/essentials/typography";
import { HamburgerMenu } from "@wpf-ui/components/fragments/hamburger-menu";
import Link from "next/link";
import { type FC } from "react";

export interface NavItemData {
  label: string;
  href: string;
}

interface NavHeaderProps {
  navItems: NavItemData[];
  activeIndex?: number;
  profilePhotoUrl: string;
}

export const NavHeader: FC<NavHeaderProps> = ({ navItems, activeIndex, profilePhotoUrl }) => {
  return (
    <Flex gap="2rem" alignItems="center" justifyContent="space-between" p={tokens.space[400].value}>
      <Flex gap="2rem" alignItems="center">
        <Box w="9rem" h="2.2rem">
          <Image src="/images/sheworks_logo.svg" alt="SHEworks" />
        </Box>
        <Flex display={{ sp: "none", pc: "flex" }} gap="1.5rem">
          {navItems.map((item, index) => {
            const isActive = activeIndex === index;
            return <NavItem key={index} item={item} isActive={isActive} />;
          })}
        </Flex>
      </Flex>

      <IconImage
        display={{ sp: "none", pc: "block" }}
        src={profilePhotoUrl}
        fallbackSrc="/images/default-user-profile.png"
        boxSize={{ sp: "40px", pc: "32px" }}
        marginBottom="0px"
        boxShadow="0px"
      />

      <Box display={{ sp: "block", pc: "none" }}>
        <HamburgerMenu navItems={navItems} activeIndex={activeIndex} />
      </Box>
    </Flex>
  );
};

interface NavItemProps {
  item: NavItemData;
  isActive: boolean;
}

const NavItem: FC<NavItemProps> = ({ item, isActive }) => {
  return (
    <Flex
      h="2.2rem"
      borderBottom="2px solid"
      borderColor={isActive ? tokens.color.text.sheblueDarken.value : "transparent"}
      alignItems="center"
    >
      <Link href={item.href}>
        <Typography
          variant="body2b"
          color={isActive ? tokens.color.text.sheblueDarken.value : tokens.color.text.light.value}
        >
          {item.label}
        </Typography>
      </Link>
    </Flex>
  );
};
