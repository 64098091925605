import { Box, Flex, Grid } from "@chakra-ui/react";
import tokens from "@sheinc/design-tokens";
import { Button } from "@sheinc/shelikes-design-system";
import { LineHeading } from "@wpf-ui/components/essentials/heading/LineHeading";
import { SheSpinner } from "@wpf-ui/components/essentials/spinner/SheSpinner";
import { Typography } from "@wpf-ui/components/essentials/typography";
import { type ProjectCardFieldsFragment } from "@wpf-ui/gql/generated-gateway/graphql";
import { ProjectCard } from "@wpf-ui/pages/projects/__components__/project-card/ProjectCard";
import { useRouter } from "next/router";
import { type FC } from "react";

interface Props {
  isLoading: boolean;
  projects: ProjectCardFieldsFragment[];
}

export const OutsourceSection: FC<Props> = ({ isLoading, projects }) => {
  const router = useRouter();
  return (
    <Flex gap="1.5rem" flexDirection="column">
      <LineHeading>
        <Typography variant="heading4b" color={tokens.color.text.default.value}>
          業務委託案件
        </Typography>
      </LineHeading>
      <Box>
        {isLoading ? (
          <Box mb="2rem">
            <SheSpinner />
          </Box>
        ) : (
          <Flex overflowX={{ sp: "scroll", pc: "unset" }} mb="2rem" width={"100%"}>
            <Grid
              templateColumns={{ sp: "repeat(4, 1fr)", pc: "repeat(1, 1fr)" }}
              gap={{ sp: "0.5rem", pc: "1rem" }}
              width={"100%"}
            >
              {projects.map((project, index) => (
                <Box key={index}>
                  <ProjectCard data={project} layoutDirection={"column"} imageWidth="100%" />
                </Box>
              ))}
            </Grid>
          </Flex>
        )}
        <Flex justifyContent={"center"}>
          <Button
            size="small"
            chevron="right"
            variant="secondary"
            onClick={() => {
              void router.push("/outsource");
            }}
          >
            すべての業務委託案件から探す
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};
