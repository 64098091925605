import React, { type ReactNode, type FC } from "react";
import {
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  useDisclosure,
  Flex,
  Box,
} from "@chakra-ui/react";
import { MdMenu, MdClose } from "react-icons/md";
import Link, { type LinkProps } from "next/link";
import { Typography } from "@wpf-ui/components/essentials/typography";
import tokens from "@sheinc/design-tokens";

interface NavItemData {
  label: string;
  href: string;
}

interface HamburgerMenuProps {
  navItems: NavItemData[];
  activeIndex?: number;
}

export const HamburgerMenu: FC<HamburgerMenuProps> = ({ navItems, activeIndex }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton variant="ghost" icon={<MdMenu size="24px" />} aria-label="Open Menu" onClick={onOpen} />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent p="0">
          <DrawerHeader>
            <IconButton
              variant="ghost"
              icon={<MdClose size="24px" />}
              aria-label="Close Menu"
              position="absolute"
              top="0.5rem"
              right="0.5rem"
              onClick={onClose}
              _hover={{
                backgroundColor: "transparent",
                color: "inherit",
              }}
            />
          </DrawerHeader>
          <DrawerBody p="0">
            <Flex direction="column">
              <Box pt="1rem" pb="10px" px="1.5rem">
                <Typography variant="body3b" color={tokens.color.text.sheblueDefault.value}>
                  新しい仕事に出会う
                </Typography>
              </Box>
              {navItems.map((item, index) => {
                const isActive = activeIndex === index;
                return (
                  <LinkItem key={index} href={item.href} isActive={isActive}>
                    {item.label}
                  </LinkItem>
                );
              })}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

type LinkItemProps = LinkProps & {
  isActive: boolean;
  children: ReactNode;
};

const LinkItem: FC<LinkItemProps> = ({ children, isActive, ...linkProps }) => (
  <Link {...linkProps} style={{ width: "100%" }}>
    <Box
      py="0.5rem"
      px="1.5rem"
      bgColor={isActive ? tokens.color.bg.sheblueLight.value : undefined}
      borderLeft={isActive ? `2px solid ${tokens.color.function.primaryDefault.value}` : undefined}
    >
      <Typography
        variant="body2b"
        color={tokens.color.text.lightest.value}
        _hover={{ color: tokens.color.function.primaryDefault.value }}
      >
        {children}
      </Typography>
    </Box>
  </Link>
);
