import { useFeatures } from "@wpf-ui/hooks";
import { TopPageContainer } from "./__components__/top-page";
import { type NextPage } from "next";
import { NotFoundPage } from "@wpf-ui/components/fragments/404/NotFoundPage";
import { DefaultLayout } from "@wpf-ui/layouts/DefaultLayout";
import tokens from "@sheinc/design-tokens";

const TopPage: NextPage = () => {
  const { topPage } = useFeatures();

  if (!topPage) {
    return <NotFoundPage />;
  }

  return (
    <DefaultLayout bgColor={tokens.color.bg.white.value} mainWidth="100%" mainPadding={"0"}>
      <TopPageContainer />
    </DefaultLayout>
  );
};

export default TopPage;
