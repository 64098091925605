import { Box, Flex } from "@chakra-ui/react";
import tokens from "@sheinc/design-tokens";
import { type ProjectCardFieldsFragment } from "@wpf-ui/gql/generated-gateway/graphql";
import { DefaultLayout } from "@wpf-ui/layouts/DefaultLayout";
import { EmploymentSection } from "@wpf-ui/pages/__components__/EmploymentSection";
import { OccupationGuideSection } from "@wpf-ui/pages/__components__/OccupationGuideSection";
import { OutsourceSection } from "@wpf-ui/pages/__components__/OutsourceSection";
import {
  RecommendedPlusLessonsByConcern,
  RecommendedPlusLessonsByInterest,
} from "@wpf-ui/pages/__components__/RecommendedPlusLessons";
import { SpotWorkSection } from "@wpf-ui/pages/__components__/SpotWorkSection";
import { type FC } from "react";

interface Props {
  isProjectLoading: boolean;
  spotProjects: ProjectCardFieldsFragment[];
  outsourceProjects: ProjectCardFieldsFragment[];
  employmentProjects: ProjectCardFieldsFragment[];
}

export const TopPagePresentation: FC<Props> = ({
  isProjectLoading,
  spotProjects,
  outsourceProjects,
  employmentProjects,
}) => {
  return (
    <>
      <Box maxW="1024px" margin="0 auto" mb={{ sp: "3.5rem", pc: "4rem" }}>
        <SpotWorkSection projects={spotProjects} isLoading={isProjectLoading} />
      </Box>
      <Flex
        maxW="1024px"
        margin="0 auto"
        mb={{ sp: "3.5rem", pc: "4rem" }}
        gap={{ sp: "6rem", pc: "1rem" }}
        flexDirection={{ sp: "column", pc: "row" }}
      >
        <Box flex={{ sp: "1", pc: "1" }}>
          <OutsourceSection projects={outsourceProjects} isLoading={isProjectLoading} />
        </Box>
        <Box flex={{ sp: "1", pc: "3" }}>
          <EmploymentSection projects={employmentProjects} isLoading={isProjectLoading} />
        </Box>
      </Flex>
      <Box maxW="1024px" margin="0 auto" mb={{ sp: "3.5rem", pc: "4rem" }}>
        <OccupationGuideSection />
      </Box>
      <Box mb={{ sp: "1.5rem", pc: "2rem" }}>
        <RecommendedPlusLessonsByInterest />
      </Box>
      <Box>
        <RecommendedPlusLessonsByConcern />
      </Box>
    </>
  );
};
