import { Box, Flex, HStack, Image, Spacer } from "@chakra-ui/react";
import { type FC, type VFC } from "react";
import { IconImage } from "@wpf-ui/components/essentials/icon";
import { Typography } from "@wpf-ui/components/essentials/typography";
import { useFeatures } from "@wpf-ui/hooks";
import { Link } from "@wpf-ui/components/essentials/link";

export interface HeaderProps {
  readonly profilePhotoUrl: string;
  readonly name: string;
  readonly showLogo?: boolean;
}

export const Header: FC<HeaderProps> = (props) => {
  const { profilePhotoUrl, name, showLogo } = props;
  const iconFallbackSrc = "/images/default-user-profile.png";
  const { topPage } = useFeatures();

  return (
    <Flex
      bg="white"
      position="sticky"
      top="0"
      zIndex="sticky"
      m="0"
      py="1rem"
      px="1.5rem"
      alignItems="center"
      justifyContent="space-between"
    >
      {showLogo && (
        <Box w="9rem" h="2.2rem" textAlign="center" flex="0 1 auto">
          {topPage ? (
            <Link href="/">
              <Image src="/images/sheworks_logo.svg" alt="SHEworks" />
            </Link>
          ) : (
            <Image src="/images/sheworks_logo.svg" alt="SHEworks" />
          )}
        </Box>
      )}
      <HStack flex="1" justifyContent="flex-end">
        <IconImage
          src={profilePhotoUrl}
          fallbackSrc={iconFallbackSrc}
          boxSize={{ sp: "40px", pc: "32px" }}
          marginBottom="0px"
          boxShadow="0px"
        />
        <Typography variant="body3" display={{ sp: "none", pc: "block" }}>
          {name}
        </Typography>
      </HStack>
    </Flex>
  );
};
