import { Box, Card, CardBody, Flex, Icon, Image, LinkBox, LinkOverlay } from "@chakra-ui/react";
import tokens from "@sheinc/design-tokens";
import { StatusLabel } from "@sheinc/shelikes-design-system";
import { Typography } from "@wpf-ui/components/essentials/typography";
import { type ProjectCardFieldsFragment } from "@wpf-ui/gql/generated-gateway/graphql";
import { formatRelativeTime, formatJa } from "@wpf-ui/utils/datetime";
import dayjs from "dayjs";
import NextLink from "next/link";
import { type FC } from "react";
import { MdLocationOn, MdWatchLater } from "react-icons/md";
import { gql } from "urql";

export const PROJECT_CARD_FIELDS = gql`
  fragment ProjectCardFields on PublicProject {
    __typename
    id
    thumbnail {
      previewUrl
    }
    name
    entryClosesAt
    workCase {
      company {
        nameFurigana
      }
    }
    jobSettings {
      jobGenre
      occupation {
        id
        name
      }
      jobPrimaryCategory {
        id
        name
      }
      jobSubCategory {
        id
        name
      }
      subOccupation {
        id
        name
      }
      jobTags {
        id
        name
      }
      locations {
        id
        name
      }
    }
  }
`;

interface Props {
  data: ProjectCardFieldsFragment;
  layoutDirection?: "row" | "column";
  imageWidth?: string;
}

export const ProjectCard: FC<Props> = ({ data, layoutDirection, imageWidth }) => {
  const now = dayjs();
  const entryClosesAt = dayjs(data.entryClosesAt);
  const isEntryClosed = now.isAfter(entryClosesAt);
  return (
    <LinkBox as="article" data-project-id={data.id}>
      <LinkOverlay as={NextLink} href={`/projects/${data.id}`}>
        <Card borderRadius="6px" minH="13.1rem" minW="13.75rem">
          <CardBody p="1rem">
            <Flex
              w="full"
              gap="1rem"
              flexDirection={{ sp: "column", pc: layoutDirection ?? "row" }}
              alignItems={{ pc: "start" }}
            >
              {data.thumbnail?.previewUrl ? (
                <Box w={{ sp: "full", pc: imageWidth ?? "20rem" }} aspectRatio="16/9">
                  <Image src={data.thumbnail.previewUrl} alt="Project card image" objectFit="cover" w="100%" h="100%" />
                </Box>
              ) : (
                <Flex
                  w={{ sp: "full", pc: imageWidth ?? "20rem" }}
                  aspectRatio="16/9"
                  backgroundImage="/images/project_thumbnail_default.jpg"
                  backgroundSize="contain"
                  backgroundRepeat="no-repeat"
                  justifyContent="center"
                  alignItems="center"
                  aria-label="デフォルトサムネイル"
                >
                  <Typography
                    color={tokens.color.text.default.value}
                    variant="body1b"
                    textAlign="center"
                    w="80%"
                    noOfLines={3}
                    aria-label="デフォルトサムネイルプロジェクトタイトル"
                  >
                    {data.name}
                  </Typography>
                </Flex>
              )}
              <Flex flexDirection="column" justifyContent="space-between" minH="11.1rem" flex="1" w="full" minW="0">
                <Flex flexDirection="column" gap="0.5rem" alignItems="flex-start" w="full">
                  {data.jobSettings?.subOccupation && (
                    <Typography color={tokens.color.text.sheblueDarken.value} variant="body3b" aria-label="サブ職種">
                      {data.jobSettings.subOccupation.name}
                    </Typography>
                  )}
                  {data.jobSettings?.jobSubCategory && (
                    <Typography color={tokens.color.text.shepinkDarken.value} variant="body3b" aria-label="小カテゴリ">
                      {data.jobSettings.jobSubCategory.name}
                    </Typography>
                  )}

                  {data.name && (
                    <Typography variant="body2b" aria-label="募集タイトル">
                      {data.name}
                    </Typography>
                  )}
                  {data.jobSettings?.jobTags && (
                    <Flex gap="0.38rem" wrap="wrap" aria-label="ジョブタグ" mb="0.5rem">
                      {data.jobSettings.jobTags.map((jobTag, index) => (
                        <StatusLabel key={index} color="defaultLight" size="S">
                          {jobTag.name}
                        </StatusLabel>
                      ))}
                    </Flex>
                  )}

                  {data.jobSettings?.locations && data.jobSettings.locations.length > 0 && (
                    <Flex aria-label="勤務地" w="full">
                      <Icon as={MdLocationOn} w="14px" h="14px" color={tokens.color.text.lightest.value} mr="0.12rem" />
                      <Typography
                        color={tokens.color.text.lightest.value}
                        variant="body3"
                        textAlign="center"
                        lineHeight="15px"
                        mr="0.38rem"
                        minW="40px"
                      >
                        勤務地
                      </Typography>
                      <Typography
                        color={tokens.color.text.default.value}
                        variant="body3"
                        textAlign="left"
                        lineHeight="15px"
                        title={data.jobSettings.locations.map((location) => location.name).join("・")}
                        isTruncated
                      >
                        {data.jobSettings.locations.map((location) => location.name).join("・")}
                      </Typography>
                    </Flex>
                  )}

                  <Flex mb={{ sp: "1rem", pc: undefined }} aria-label="応募締切">
                    <Icon as={MdWatchLater} w="14px" h="14px" color={tokens.color.text.lightest.value} mr="0.12rem" />
                    <Typography
                      color={tokens.color.text.lightest.value}
                      variant="body3"
                      textAlign="center"
                      lineHeight="15px"
                      mr="0.38rem"
                      minW="50px"
                    >
                      応募締切
                    </Typography>
                    {isEntryClosed ? (
                      <Typography
                        color={tokens.color.text.danger.value}
                        variant="body3"
                        textAlign="left"
                        lineHeight="15px"
                      >
                        募集は終了しました
                      </Typography>
                    ) : (
                      <Typography
                        color={tokens.color.text.default.value}
                        variant="body3"
                        textAlign="left"
                        lineHeight="15px"
                      >
                        <span
                          style={
                            // 1日未満の場合は赤色にする
                            entryClosesAt.diff(now, "day") < 1 ? { color: tokens.color.text.danger.value } : undefined
                          }
                        >
                          {formatRelativeTime(now.toISOString(), entryClosesAt.toISOString())}
                        </span>
                        {`（${formatJa(entryClosesAt, "YYYY/MM/DD HH:mm")}まで）`}
                      </Typography>
                    )}
                  </Flex>
                </Flex>
                <Typography color={tokens.color.text.default.value} variant="body3" mt="auto">
                  {data.workCase?.company?.nameFurigana ? data.workCase.company.nameFurigana : "SHE株式会社"}
                </Typography>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </LinkOverlay>
    </LinkBox>
  );
};
