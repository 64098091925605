import { Flex } from "@chakra-ui/react";
import tokens from "@sheinc/design-tokens";
import { Typography } from "@wpf-ui/components/essentials/typography";
import { type FC } from "react";

interface Props {
  children: React.ReactNode;
  bgColor?: string;
  lineColor?: string;
  padding?: string;
}

export const LineHeading: FC<Props> = ({ children, bgColor, lineColor, padding }) => {
  return (
    <Flex
      bgColor={bgColor ?? tokens.color.primitive.sheblue100.value}
      padding={`${padding ?? "0.5rem 1.5rem"} `}
      alignContent="center"
      borderLeft={`5px solid ${lineColor ?? tokens.color.object.sheblueDarken.value}`}
    >
      {children}
    </Flex>
  );
};
