import { type FC } from "react";
import { Box } from "@chakra-ui/react";
import { useCurrentUser } from "@wpf-ui/contexts";
import { NavHeader } from "@wpf-ui/components/fragments/header/NavHeader";
import { useFeatures } from "@wpf-ui/hooks";
import { FooterSection } from "@wpf-ui/components/fragments/footer/FooterSection";
import { DefaultLayout as OldDefaultLayout } from "@wpf-ui/layouts/default";
interface DefaultLayoutProps {
  readonly children: React.ReactNode;
  bgColor?: string;
  mainWidth?: string;
  mainPadding?: string;
  activeIndex?: number;
  showLogo?: boolean;
}

const NAV_ITEMS = [
  {
    label: "正社員求人を探す",
    href: "/employment",
  },
  {
    label: "業務委託求人を探す",
    href: "/outsource",
  },
  {
    label: "単発のお仕事を探す",
    href: "/spotwork",
  },
];

export const DefaultLayout: FC<DefaultLayoutProps> = (props: DefaultLayoutProps) => {
  const currentUser = useCurrentUser();
  const { topPage } = useFeatures();
  const { children, bgColor, mainWidth = "1024px", mainPadding, activeIndex } = props;

  return (
    <>
      {!topPage ? (
        <OldDefaultLayout {...props}>{children}</OldDefaultLayout>
      ) : (
        <Box bg={bgColor} h="100%" minHeight="100vh">
          {currentUser && (
            <NavHeader activeIndex={activeIndex} navItems={NAV_ITEMS} profilePhotoUrl={currentUser.profilePhotoUrl} />
          )}
          <Box
            as="main"
            w={{ pc: mainWidth, sp: "100%" }}
            m="0 auto"
            px={{ sp: mainPadding ?? "1.5rem", pc: 0 }}
            py="2rem"
          >
            {children}
          </Box>
          <FooterSection />
        </Box>
      )}
    </>
  );
};
