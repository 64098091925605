import { useSearchPublicProjectsQuery } from "@wpf-ui/hooks/useSearchPublicProjectsQuery";
import { type FC } from "react";
import { TopPagePresentation } from "./TopPagePresentation";
import { useTopPageContainer } from "@wpf-ui/pages/__components__/top-page/__hooks__/useTopPageContainer";

export const TopPageContainer: FC = () => {
  const { isProjectLoading, spotProjects, outsourceProjects, employmentProjects } = useTopPageContainer();
  return (
    <TopPagePresentation
      isProjectLoading={isProjectLoading}
      spotProjects={spotProjects}
      outsourceProjects={outsourceProjects}
      employmentProjects={employmentProjects}
    />
  );
};
